// src/Pages/LandingPage.js

import React, { useState } from 'react';
import Welcome from '../Components/Welcome';
import VideoBackground from '../Components/VideoBackground';
import Header from '../Components/Header';
import SearchForm from '../Components/SearchForm';
import './LandingPage.css';

const LandingPage = () => {
  const [isJourneyStarted, setIsJourneyStarted] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isRSVPStarted, setIsRSVPStarted] = useState(false);

  const handleStartJourney = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsJourneyStarted(true);
      setIsTransitioning(false);
    }, 1000);
  };

  return (
    <div className="landing-page">
      <div
        className={`welcome-section ${
          isTransitioning ? 'fade-out' : ''
        } ${isJourneyStarted ? 'hidden' : ''}`}
      >
        <Welcome onStartJourney={handleStartJourney} />
      </div>
      <div className={`main-content ${isJourneyStarted ? 'fade-in' : ''}`}>
        <VideoBackground />
        <div className="form-overlay">
          <Header showSubtitle={!isRSVPStarted} />
          <SearchForm setIsRSVPStarted={setIsRSVPStarted} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
