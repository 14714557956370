// src/Components/Header.js

import React from 'react';
import './Header.css';

const Header = ({ showSubtitle }) => {
  return (
    <div className={`header ${!showSubtitle ? 'no-subtitle' : ''}`}>
      <h1 className="title">Shakeel & Imaan</h1>
      {showSubtitle && (
        <h2 className="subtitle">
          To Begin Your Journey Please Start By Searching For Your Name
        </h2>
      )}
    </div>
  );
};

export default Header;
