// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAoUWJLDM78FZmkU2-VwseL1kuSSe5Xk6k",
  authDomain: "friendly-city-gsd.firebaseapp.com",
  projectId: "friendly-city-gsd",
  storageBucket: "friendly-city-gsd.appspot.com",
  messagingSenderId: "171085497325",
  appId: "1:171085497325:web:a40fdd37f89d813e1bdde4",
  measurementId: "G-PT69EX1MB0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and export it
const db = getFirestore(app); // Firestore initialization

export { db }; // Export db to be used in other components
