// src/Components/AccommodationInfo.js

import React from 'react';
import './AccommodationInfo.css';

const AccommodationInfo = ({ url, discountCode }) => {
  return (
    <div className="accommodation-info">
      <h2>Accommodation Booking Information</h2>
      <p>
        Please note to book accommodation you will need to follow the link below to the event site.
      </p>
      <p className="discount-code">
        <strong>Use The Following Reference:</strong> <div className='disc_code'>{discountCode}</div>
      </p>
      <a href={url} target="_blank" rel="noopener noreferrer" className="accommodation-link">
        Book Accommodation
      </a>

    </div>
  );
};

export default AccommodationInfo;
