import React, { useState } from 'react';
import { db } from '../FirebaseConfig';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import './SearchForm.css';
import RSVPQuestions from './RSVPQuestions';
import AccommodationInfo from './AccommodationInfo';
import { FaUserCheck, FaHotel, FaUtensils, FaAllergies, FaFish, FaLeaf, FaCarrot, FaTimesCircle } from 'react-icons/fa'; // Import icons


const SearchForm = ({ setIsRSVPStarted }) => {
  const [searchName, setSearchName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [guestData, setGuestData] = useState(null);
  const [showRSVPQuestions, setShowRSVPQuestions] = useState(false);
  const [rsvpCompleted, setRsvpCompleted] = useState(false);
  const [rsvpSubmitted, setRsvpSubmitted] = useState(false);
  const [answers, setAnswers] = useState({});

  const handleSearch = async () => {
    setErrorMessage("");
    setGuestData(null);
    setRsvpCompleted(false);

    if (!searchName) {
      setErrorMessage("Please enter your name.");
      return;
    }

    try {
      const guestsRef = collection(db, "wedding_rsvp_invitelist");
      const querySnapshot = await getDocs(guestsRef);

      let foundGuests = [];
      const searchLower = searchName.toLowerCase();

      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        const inviteName = data.invite_name.toLowerCase();

        if (inviteName.includes(searchLower)) {
          foundGuests.push({
            id: docSnapshot.id,
            ...data,
          });
        }
      });

      if (foundGuests.length === 1) {
        const guest = foundGuests[0];
        setGuestData(guest);

        // Check if RSVP is already completed
        if (guest.rsvpCompleted) {
          setRsvpCompleted(true);
        }
      } else if (foundGuests.length > 1) {
        setErrorMessage("Multiple guests found. Please refine your search.");
      } else {
        setErrorMessage("No guest found with this name.");
      }
    } catch (error) {
      console.error("Error searching for guest:", error);
      setErrorMessage("Error occurred while searching. Please try again.");
    }
  };

  const handleContinue = () => {
    setShowRSVPQuestions(true);
    setIsRSVPStarted(true);
  };
  const handleRSVPSubmit = async (answers) => {
    try {
      const guestRef = doc(db, 'wedding_rsvp_invitelist', guestData.id);

      await updateDoc(guestRef, {
        ...answers,
        rsvpTimestamp: new Date(),
        rsvpCompleted: true,
      });

      console.log('RSVP submitted successfully');

      // Store the answers in state
      setAnswers(answers);

      // Indicate that RSVP has been submitted
      setRsvpSubmitted(true);

      // Do not reset other state variables yet

    } catch (error) {
      console.error('Error submitting RSVP:', error);
      setErrorMessage('Error occurred while submitting RSVP. Please try again.');
    }
  };

    // Function to reset the form and go back to search form
    const handleReset = () => {
      setGuestData(null);
      setShowRSVPQuestions(false);
      setSearchName('');
      setErrorMessage('');
      setIsRSVPStarted(false);
      setRsvpSubmitted(false);
      setRsvpCompleted(false); // Reset rsvpCompleted to false
      setAnswers({});
    };

  // Mapping of field keys to user-friendly labels
  const fieldLabels = {
    willAttend: { label: 'Attendance Option', icon: <FaUserCheck /> },
    willBookAccommodation: { label: 'Accommodation', icon: <FaHotel /> },
    dietaryPreference: { label: 'Dietary Preference', icon: <FaUtensils /> },
    allergies: { label: 'Allergies', icon: <FaAllergies /> },
  };


  return (
    <div className="search-form">
      {!guestData && !showRSVPQuestions && !rsvpSubmitted && (
        <>
          <input
            type="text"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder="Enter your name"
            className="search-input"
          />
          <button onClick={handleSearch} className="search-button">Let's Begin</button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </>
      )}

      {guestData && !showRSVPQuestions && !rsvpSubmitted && !rsvpCompleted && (
        <div className="guest-confirmation-box">
          <p>Guest Name Found: <strong>{guestData.invite_name}</strong></p>
          <button onClick={handleContinue} className="continue-button">Continue</button>
        </div>
      )}

      {rsvpCompleted && !rsvpSubmitted && (
        <div className="rsvp-completed-message">
          <h2 className="thank-you-title">Thank You, {guestData.invite_name}!</h2>
          <p>We have received your RSVP. Here are your responses:</p>
          <div className="response-cards">
            {Object.entries(fieldLabels).map(([key, { label, icon }]) => (
              <div key={key} className="response-card">
                <div className="icon-container">{icon}</div>
                <div className="response-details">
                  <h3>{label}</h3>
                  <p>{guestData[key] || 'N/A'}</p>
                </div>
              </div>
            ))}
          </div>
          <button onClick={handleReset} className="reset-button">Back to Search</button>
        </div>
      )}

      {showRSVPQuestions && !rsvpSubmitted && (
        <RSVPQuestions onSubmit={handleRSVPSubmit} guestData={guestData} />
      )}

      {rsvpSubmitted && (
        <>
          {answers.willBookAccommodation === 'Yes' ? (
            <AccommodationInfo
              url="https://book.nightsbridge.com/29945" // Update with your URL
              discountCode="Shakeel & Imaan" // Update with your code
            />
          ) : (
            <div className="thank-you-message">
              <h2>Thank you for your RSVP!</h2>
              <p>We look forward to celebrating with you.</p>
            </div>
          )}
          <button onClick={handleReset} className="reset-button">Back to Search</button>
        </>
      )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default SearchForm;