// src/Components/VideoBackground.js

import React, { useEffect, useRef } from 'react';
import videoBack from '../Assets/video_Background-3.mp4';
import './VideoBackground.css';
// Remove the poster if it's causing issues
// import Poster from '../Assets/poster_image.png';

const VideoBackground = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Ensure the video is muted
      video.muted = true;
      // Ensure the video plays inline
      video.setAttribute('playsinline', '');
      video.setAttribute('webkit-playsinline', '');

      // Attempt to play the video
      video.play().catch((error) => {
        console.error('Error attempting to play', error);
      });
    }
  }, []);

  return (
    <div className="video-background-container">
      <video
        ref={videoRef}
        className="video-background"
        autoPlay
        loop
        muted
        playsInline
        webkit-playsinline="true"
        preload="auto"
        // Remove poster if it's causing the play button to appear
        // poster={Poster}
      >
        <source src={videoBack} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground;
