// src/Components/Welcome.js

import React, { useEffect, useRef, useState } from "react";
import "./Welcome.css";
import planeAnimation from "../Assets/plane_animation_gr.mp4";
import coupleImage from "../Assets/rsvp-image-1.jpg";
import ButtonCustom from "./ButtonCustom";
import Poster_Landing from '../Assets/poster_landing.png'

const Welcome = ({ onStartJourney }) => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Ensure the video is muted
      video.muted = true;
      // Ensure the video plays inline
      video.setAttribute('playsinline', '');
      video.setAttribute('webkit-playsinline', '');

      // Load the video (optional)
      video.load();
    }

    // Simulate a loading time
    const loadingTimeout = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // Adjust the time as needed

    return () => clearTimeout(loadingTimeout);
  }, []);

  const handleStartJourney = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
      });
    }
    // Proceed with the rest of your logic
    onStartJourney();
  };

  return (
    <>
      {!isLoaded && (
        <div className="loading-screen">
          <div className="loader">
            <div className="loader-inner"></div>
          </div>
        </div>
      )}
      {isLoaded && (
        <div className="welcome-container">
          <video
            ref={videoRef}
            className="welcome-video-background"
            autoPlay
            loop
            muted
            playsInline
            poster={Poster_Landing}
            webkit-playsinline="true"
            preload="auto"
          >
            <source src={planeAnimation} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className="welcome-content">
            <h2 className="welcome-title">Join Our Journey</h2>
            <h1 className="title-welcome">Shakeel & Imaan</h1>
            <img src={coupleImage} alt="Couple" className="couple-image" />
            <ButtonCustom
              onClick={handleStartJourney}
              text="Begin Your Journey With Us"
              fontSize="26px"
              fontWeight="normal"
              position="center"
              backgroundColor="#63C5DA0000"
              textColor="#fff"
              shadowColor="#FFA500"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Welcome;
