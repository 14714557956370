import React, { useEffect, useRef } from 'react';
import './ButtonCustom.css';

const ButtonCustom = ({
  text = 'Click Me!',
  fontSize = '16px',
  fontWeight = '600',
  position = 'center',
  onClick,
  backgroundColor = '#00000000',
  textColor = '#fff',
  shadowColor = '#388e3c',
}) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      button.classList.add('button-load-animation');
    }
  }, []);

  const handleClick = () => {
    const button = buttonRef.current;
    if (button) {
      button.classList.add('button-click-animation');
    }
    if (onClick) {
      onClick();
    }
    // Add a fade transition to the next screen
    document.body.classList.add('fade-transition');
  };

  return (
    <div className={`button-container button-container-${position}`}>
      <button
        ref={buttonRef}
        className="button-custom"
        onClick={handleClick}
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          backgroundColor: backgroundColor,
          color: textColor,
        }}
      >
        <span className="button-text-wrapper">
          {text}
        </span>
      </button>
    </div>
  );
};

export default ButtonCustom;