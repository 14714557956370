// src/Components/RSVPQuestions.js

import React, { useState } from 'react';
import './RSVPQuestions.css';
import { FaUserCheck, FaHotel, FaUtensils, FaAllergies, FaFish, FaLeaf, FaCarrot, FaTimesCircle } from 'react-icons/fa'; // Import icons

const RSVPQuestions = ({ onSubmit, guestData }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);
  const [rsvpSubmitted, setRsvpSubmitted] = useState(false);

  const questions = [
    {
      id: 'willAttend',
      question: 'Please select your attendance option:',
      type: 'multipleChoice',
      options: [
        'Nikkah & Reception',
        'Nikkah Only',
        'Reception Only',
        'I Will Not Be Attending',
      ],
    },
    {
      id: 'willBookAccommodation',
      question: 'Will you be booking accommodation?',
      type: 'yesNo',
      condition: (answers) => answers.willAttend !== 'I Will Not Be Attending',
    },
    {
      id: 'dietaryPreference',
      question: 'Please select your dietary preference:',
      type: 'dietaryOptions', // Changed from 'dropdown' to a custom type
      options: [
        { value: 'Pescatarian', icon: <FaFish /> },
        { value: 'Vegetarian', icon: <FaLeaf /> },
        { value: 'Vegan', icon: <FaCarrot /> },
        { value: 'None', icon: <FaTimesCircle /> },
      ],
      condition: (answers) => answers.willAttend !== 'I Will Not Be Attending',
    },
    {
      id: 'allergies',
      question: 'Please specify any allergies you may have:',
      type: 'text',
      condition: (answers) => answers.willAttend !== 'I Will Not Be Attending',
    },
    // Add any additional questions here
  ];

  const handleAnswer = (id, value) => {
    const updatedAnswers = {
      ...answers,
      [id]: value,
    };
    setAnswers(updatedAnswers);
    handleNextQuestion(updatedAnswers);
  };

  const handleNextQuestion = (updatedAnswers) => {
    let nextIndex = currentQuestionIndex + 1;
    while (nextIndex < questions.length) {
      const nextQuestion = questions[nextIndex];
      if (!nextQuestion.condition || nextQuestion.condition(updatedAnswers)) {
        setCurrentQuestionIndex(nextIndex);
        return;
      }
      nextIndex += 1;
    }
    // If no more valid questions, show summary
    setShowSummary(true);
  };

  const handleSubmit = () => {
    onSubmit(answers);
    setRsvpSubmitted(true);
  };

  const currentQuestion = questions[currentQuestionIndex];

  // Mapping of field keys to user-friendly labels and icons
  const fieldLabels = {
    willAttend: { label: 'Attendance Option', icon: <FaUserCheck /> },
    willBookAccommodation: { label: 'Accommodation', icon: <FaHotel /> },
    dietaryPreference: { label: 'Dietary Preference', icon: <FaUtensils /> },
    allergies: { label: 'Allergies', icon: <FaAllergies /> },
  };

  if (rsvpSubmitted) {
    // After RSVP is submitted
    if (answers.willBookAccommodation === 'Yes') {
      // Show accommodation info

    } else {
      // Show thank you message
      return (
        <div className="thank-you-message">
          <h2>Thank you for your RSVP!</h2>
          <p>We look forward to celebrating with you.</p>
        </div>
      );
    }
  }

  if (showSummary || !currentQuestion) {
    // Display the enhanced summary screen
    return (
      <div className="rsvp-summary">
        <h2 className="summary-title">Review Your Responses</h2>
        <div className="response-grid">
          {Object.entries(fieldLabels).map(([key, { label, icon }]) => (
            <div key={key} className="response-item">
              <div className="response-icon">{icon}</div>
              <div className="response-text">
                <h3>{label}</h3>
                <p>{answers[key] || 'N/A'}</p>
              </div>
            </div>
          ))}
        </div>
        <button onClick={handleSubmit} className="submit-button">
          Submit Final
        </button>
      </div>
    );
  }

  return (
    <div className="rsvp-question-container">
      <h2 className="rsvp-question">{currentQuestion.question}</h2>

      {currentQuestion.type === 'multipleChoice' && (
        <div className="multiple-choice-options">
          {currentQuestion.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(currentQuestion.id, option)}
              className="multiple-choice-button"
            >
              {option}
            </button>
          ))}
        </div>
      )}

      {currentQuestion.type === 'yesNo' && (
        <div className="yes-no-buttons">
          <button
            onClick={() => handleAnswer(currentQuestion.id, 'Yes')}
            className="yes-button"
          >
            Yes
          </button>
          <button
            onClick={() => handleAnswer(currentQuestion.id, 'No')}
            className="no-button"
          >
            No
          </button>
        </div>
      )}

      {currentQuestion.type === 'dietaryOptions' && (
        <div className="dietary-options">
          {currentQuestion.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(currentQuestion.id, option.value)}
              className={`dietary-option-button ${
                answers[currentQuestion.id] === option.value ? 'selected' : ''
              }`}
            >
              <div className="dietary-icon">{option.icon}</div>
              <span>{option.value}</span>
            </button>
          ))}
        </div>
      )}

      {currentQuestion.type === 'text' && (
        <div className="text-input-container">
          <textarea
            className="text-input"
            placeholder="Enter your allergies here..."
            value={answers[currentQuestion.id] || ''}
            onChange={(e) => {
              const value = e.target.value;
              setAnswers({
                ...answers,
                [currentQuestion.id]: value,
              });
            }}
          />
          <button
            onClick={() => handleNextQuestion(answers)}
            className="text-submit-button"
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default RSVPQuestions;
